@import "../../../../assets/scss/custom";

.Table {
  margin-top: 2rem;
  font-size: 0.8rem;
}

.TableHeader {
  text-transform: uppercase;
  color: $light;
  background-color: $table-header-bg;
  font-weight: 500;
  letter-spacing: 0.15rem;

  th {
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}

.TableRow {
  border-radius: 4px;
  background: $content-bg;
  margin: 1rem 0;
  padding: 1rem;
  // border-bottom: 1rem solid $body-bg;
  font-weight: 500;
  cursor: pointer;
  
  td {
    vertical-align: middle;
  }
}

.Buttons {
  width: 1px;
  white-space: nowrap;

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}
