@import '../../../../../assets/scss/variables';
@import "../../../../../assets/scss/custom";

.Content {
  padding: 0 $brandWidth;
  margin: 3rem 0;
}

.Line {
  border: 3px solid $gray-4;
  margin: 1.5rem 0;
  border-spacing: 3px; 
  background: $gray-4;
}