@import "../../../../../../assets/scss/variables";
@import "../../../../../../assets/scss/custom";
@import "../../../../../../assets/scss/bootstrap-helpers";

.Container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $brandWidth;
  justify-content: space-between;
}

.ResendButton {
  color: $white;
  font-weight: bold;
}

.Buttons {
  display: flex;
  button:not(:first-child) {
    margin-left: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .None {
    display: none;
  }

  .Container {
    justify-content: unset;
    padding: 0;
    display: grid;
  }

  .Buttons {
      button:not(:first-child) {
        margin-left: 0.25rem;
      }
  }

  .View {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .Draft {
    .Buttons {
      margin-right: 6rem;
    }
  }

  .Sent {
    .Buttons {      
      margin-right: 6rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem;
    }
  }
}
