.Actions {
  display: flex;
  align-items: center;

  button {
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}