@import "../../../../../../../assets/scss/custom";

.Container {
  background-color: $gray-1;
  color: #FFFFFF;
  padding: 1rem;
  height: 100%;
  border-bottom: solid thin white;
}

.Divider {
  height: 1px;
  background-color: #FFFFFF;
}

.Row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;

  p {
    color: #FFFFFF !important;
    margin-bottom: 0 !important;
  }
}

.Heading {
  color: #FFFFFF !important;
  font-size: 1.5rem;
}
