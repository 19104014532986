@import "../../../../../../../assets/scss/custom";

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;
  margin: 0.5rem;
  padding: 0.5rem 0 0.5rem 0;
}

.Text {
  margin: 0.5rem 0 0 0;
  font-weight: bold;
  font-size: $font-size-sm;
	text-align:center;
}
