@import '../../../assets/scss/custom';

.Icon {
  color: $gray-4;
  margin: 0 0.5rem 0 0;
}

.ExpirationDate {
  @include subtitle;
  margin: 0 1rem 0 0;
}

.ExpirationStatus {
  @include subtitle;
}

.Expiring {
  color: $yellow-1;
}

.Expired {
  color: $red-1;
}