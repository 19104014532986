.statisticsTable thead {
	text-align: center;
}
.statisticsTable tbody td {
	text-align: center;
}

.statisticsTable tbody .even {
	background: #eeeeee;
}

.statisticsTable thead .even {
	background: #444444;
}