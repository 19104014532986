.OtherRecipients {
  font-weight: bold;
  margin: 1rem 0 0.25rem 0;
}

.ErrorMessage p {
  margin: 0;
}

.border {
  border:solid 1px #ddd;
  border-radius:5px;
  padding:5px;
  margin:5px;
}