@import '../../assets/scss/bootstrap-helpers';
@import "../../assets/scss/variables";

.root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.LogoContainer {
    padding: 12px 24px;
}

.Container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/*
@each $breakpoint in xl md {
    @include media-breakpoint-up($breakpoint) {
        .Container {
            padding-left: $brandWidth;
            padding-right: $brandWidth;
        }
    }
}
*/

