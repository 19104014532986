@import '../../../assets/scss/custom';

.Button {
  display: flex;
  color: $link-blue-color;
  font-weight: bold;
  align-items: center;
}

.Icon {
  margin-right: 0.5rem;
}