@import '../../../assets/scss/variables';
@import '../../../assets/scss/custom';

.Container {
  margin-bottom: 0;
  padding: 0.75rem $brandWidth;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Back {
  position: absolute;
  left: $brandWidth;
}


.BackButton {
  text-decoration: underline;
  color: $text-color-dark;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
}

.BackIcon {
  margin-right: 1rem;
}

.Center {
  font-weight: 500;
}