@import "../../../../../../assets/scss/custom";

.OfferCard {
  min-height: 5.5rem;
  padding: 1rem;
  padding-top: 0.75rem;
  border-radius: $border-radius;
  margin: 0 1rem 0.75rem 1rem;
  cursor: pointer;
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);

//    .OfferName {
//      display: block;
//    }
  }
}

.CardBody {
  padding: 0;
}

.Title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.CustomerName {
  font-size: $font-size-md;
  font-weight: 500;
}

.OfferName {
  font-size: $font-size-label;
  color: $gray-3;
  font-weight: 500;
//  display: none;
}

.Details {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-xs;
  font-weight: bold;
  color: $gray-4;
  margin-top: 1rem;
}

.ExpirationDate {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem
  }
}

.Views {
  display: flex;
  align-items: center;
  margin-left: auto;
  svg {
    margin-left: 0.5rem;
  }
}

.Error {
  background-color: $gray-1;
  color: $gray-6;
}

.ErrorIcon {
  font-size: $font-size-xs;
  svg {
    margin-right: 0.5rem;
  }
}
