@import '../../../assets/scss/custom';

.Input {
  @include input-styles;
}

/*
.Input:focus {
  background: $input-bg;
}
*/

/*
.Input::placeholder {
  color: #828282;
  padding: 0;
  margin: 0;
}
*/

.Label {
  font-weight: bold;
  font-size: $font-size-sm;
  margin-bottom: 0.25rem;
}

.FontPicker {

  :global(.expanded) {
    z-index: 2;
    
    ul {
      border-top: 1px solid #BDBDBD;
    }
  }

  > div {
    @include input-styles;
    background: none !important;
    border: 1px solid #BDBDBD;
    font-size: $font-size-sm;
    width: 100%;
    box-shadow: none;

    button {
      background: #FFF !important;
    }

    ul {
      background: #FFF;

      button:hover {
        background: #DDD !important;
      }

      :global(.active-font) {
        background: #d1d1d1 !important;
      }
      
    }
  }

  

}

.tel {
  height: 35px;
  width: 100%;
}

.intlTel {
  font-size: 14px;
}

.groupWithSubmitInput {
  display: flex;
  flex-direction: row;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  input {
    flex-grow: 2;
    border: none;
    :focus {
      outline: none;
    }
  }
  button {
    background-color: #6FCF97;
    border: none;
    font-size: 0.875rem;
    padding: 0 1rem;
  }
}


.WithButton {
  display: flex;

  > input, > select, > div:first-child {
    margin-right: 1rem;
    flex-grow: 1;
  }

  button {
    white-space: nowrap;
  }
}