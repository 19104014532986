@import "../../../assets/scss/custom";

.Container {
  grid-area: sidebar;
  width: 100%;
  background: #FFF;
  z-index: 101;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.Shadow {
  -webkit-box-shadow: -10px 0px 10px -5px rgba(221, 211, 211, 1);
  -moz-box-shadow: -10px 0px 10px -5px rgba(211, 211, 211, 1);
  box-shadow: -10px 0px 10px -5px rgba(211, 211, 211, 1);
}

@media (max-width: $mobile-width) {
  .MobileMargin {
    width: unset;
    margin: 1rem;
  }
}

@media (min-width: $mobile-width + 1) {
  .Scrollable {
    // height = viewport - header 58px - footer 4.5rem
    max-height: calc(100vh - 58px - 4.5rem);
    
		
		//position: fixed;		
    //top: 58px;
		
		position:sticky;
		top:0;

    bottom: 0;
    right: 0;
    width: 480px;
    z-index: unset;
  }

  .NoTop {
    top: 0;
    // height = viewport - footer 4.5rem
    max-height: calc(100vh - 4.5rem);
  }
}
