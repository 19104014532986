@import "../../../../../assets/scss/custom";
@import "../../../../../assets/scss/bootstrap-helpers";

.MobileFooter {
  display: none;
}

.Container {
  grid-template-columns: auto 400px;
}

.Printable {
  display: block !important;
}

@media (max-width: $mobile-width) {
  .Container {
    display: block !important;
    margin-bottom: 4rem;
  }

  .MobileFooter {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: block;
    padding: 0.5rem;
    text-align: center;
    background: rgba(91, 91, 91, 0.5);
  }
}
