@import '../../../../../../../assets/scss/custom';

.Button {
		width:24px;
		padding:0;
		height:24px;

		position:absolute;
		left:5px;
		top:5px;

}

.bsize1 { font-size:1.0em; }
.bsize2 { font-size:0.9em; }
.bsize3 { font-size:0.8em; }
.bsize4 { font-size:0.7em; }
.bsize5 { font-size:0.6em; }
.bsize6 { font-size:0.5em; }

.customHolder {
		position:relative;
}

.customInput {
		padding-left:40px;
		padding-right:40px;
}

.trashBtn {
		position:absolute;
		right:5px;
		top:5px;
}
