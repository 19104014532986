@import '../../../assets/scss/custom';

.PreviewItem {
  padding: 0.25rem;
  border-radius: $border-radius;
  margin: 0 0 0.375rem 0.75rem;
  border: 1px solid $gray-4;
  position: relative;
  img {
    max-width: 50px;
    max-height: 50px;
  }

  &:hover .PreviewItemDelete {
    display: flex;
  }
}

.PreviewItemDelete {
  position: absolute;
  top: -5px;
  right: -5px;
  background: $gray-4;
  width: 1.25rem;
  height: 1.25rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
}