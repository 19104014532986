@import '../../../../../../../assets/scss/custom';

.Container {
  text-align: center;
}

.MemberName {
  font-weight: 600;
  margin: 0;
}

.Title {
  font-weight: 500;
  margin: 0;
}

.Dialog {
  color: $link-color;
  cursor: pointer;
}

.Item {

  img {
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 320px;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
  }

}
