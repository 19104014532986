@import '../../../assets/scss/custom';
@import '../../../assets/scss/bootstrap-helpers';

.Container {
  height: 4.5rem;
  background: $gray-1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 102;
}

.Fill {
  display: block;
  height: 4.5rem;
}

@include media-breakpoint-down(xs) {
  .MobileFooter {
    height: 8rem;
  }
}
