.CustomerDetails {
  display: flex;
  justify-content: space-between;
}

.Title {
  width: 100%;
}

.Select {
  width: 100%;
}

.Select > div {
  width:unset !important;
}

.Row {
  display: flex;
}

.Left {
  width: 100%;
  margin-right: 4px;
}

.Right {
  width: 100%;
  margin-left: 4px;
}

.Language {
  width: 70%;
  margin-left: 4px;
}
