@import '../../../assets/scss/custom';

.Container {
  position: relative;
  width: 260px;
}

.Icon {
  position: absolute;
  top: 7px;
  left: 7px;
}

.Input {
  display: flex;
  border: 1px solid $gray-4;
  background: $input-bg;
  border-radius: 4px;
  padding-left: 1.75rem;
}

.Input::placeholder {
  color: #2d9cdb;
  text-align: right;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.Search {
  position: absolute;
  width: 100%;
  padding: 0.2rem;
  z-index: 1000;
}
