@import "../../assets/scss/custom";

.NavBar {
  margin-bottom: 0;
  border-bottom: 1px solid #E5E5E5;
  z-index: 1000;
}

.UserImage {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  border: 1px solid $gray-4;
  margin-right: 0.5rem;
}
