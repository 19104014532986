.Label {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.Card {
  border-radius: 4px;
  font-size: 0.875rem;
  border: 1px solid #BDBDBD;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
