@import "../../../../../../../assets/scss/custom";

.Container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 0.5rem;
}

.Title {
  display: inline-block;
  margin: 0;
  font-weight: bold;
}

.Right {
  display: flex;

  button {
    padding: 0;
    margin-right: 0.5rem;
  }

  .EditButton {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }
  
}
