@import "../../../../../../../assets/scss/custom";

.Container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 0.5rem;
}


.ContainerAdd {
  border: 1px solid $gray-4;
  border-radius: 0;
}

.Title {
  display: inline-block;
  margin: 0;
  font-weight: bold;
}
