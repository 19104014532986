@import "../../../assets/scss/custom";

.Container {
  min-width: 1.5rem;
  height: 1.5rem;
  background: $gray-5;
  border-radius: $border-radius-sm;
  text-align: center;
  font-size: initial;

  svg {
    color: $secondary;
  }
}

.Active {
  background: $blue-2;
  svg {
    color: $water-light;
  }
}

.Red {
  svg {
    color: $red-1;
  }
}

.Clickable {
  cursor: pointer;
}
