@import "../../../../assets/scss/custom";

/*
.Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
*/
.Buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}


.Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));

  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.Buttons {
	position:absolute;
	bottom:15px;
	left:20px;
	right:20px;
}

.Card {
	max-width:500px;
}

.CardBody {
	padding-bottom:40px;
}
