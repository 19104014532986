@import '../../../../../../../assets/scss/custom';

.Input {
  @include input-styles;
}

.Header {
  display: flex;
}

.Plus, .Minus {
  margin-left: 0.5rem;
  cursor: pointer;
}

.FormGroup {
  display: flex;
  align-items: center;
}
