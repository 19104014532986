.body {
  border:solid 1px #ddd;
  padding:10px;
  margin:20px 0px;
}

.link {
    word-break: break-all;
    font-family: monospace;
    font-size: 0.8em;

}

