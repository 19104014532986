@import "../../../../../../assets/scss/custom";

.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - 131px);
  width: 100%;
  max-width: 480px;
  z-index: 101;
}

.ContainerSimple {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  z-index: 101;
  margin:auto;
  padding:15px;
}

.Body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.FooterButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DeleteButton,
.DeleteButton:hover {
  color: $red-1;
  font-weight: bold;
}

.OfferComments {
  margin-top: 1rem;
}

@media (max-width: $mobile-width) {
  .Container {
    position: relative;
  }
}
