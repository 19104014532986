.method {
		display:inline-block;
		border-radius:5px;
		padding:10px;
		margin:10px;
		background:#ffffff;
		box-shadow:5px 5px 10px 2px rgba(0,0,0,0.3);
		text-align:center;
		cursor:pointer;
}

.method > img {
  height:50px;
}

.method:hover {
	box-shadow:8px 8px 10px 2px rgba(0,0,0,0.3);

}

.loading {
	box-shadow:1px 1px 1px 1px rgba(0,0,0,0.3) !important;
}

.nohetu {
	box-shadow:1px 1px 1px 1px rgba(0,0,0,0.3) !important;
	opacity:0.5;
}

.nohetu:hover {
		cursor:unset;		
}
