.Group {
  display: flex;
  align-items: center;

  label {
    width: 4rem;
  }

  :global(.form-control) {
    width: 4rem;
    margin: 0 0 0 1rem;
  }
}