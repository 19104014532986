@import "custom";
@import "variables";
@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

* {
  box-sizing: border-box;
}

.pagebreakafter {
  page-break-after: always;
}

.pagebreakbefore {
  page-break-before: always;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
}

body.login-bg {
  background: $primary;
}

.flex {
  display: flex;
}

label {
  font-size: $font-size-label;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.navbar {
  padding: 0;
  height: $navbarHeight;
  background: $content-bg;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    .dropdown-menu {
      margin-right: 1rem;
    }
  }

  .navbar-toggler {
    margin-right: 1rem;
  }

  .navbar-collapse {
    background: $content-bg;
    z-index: 999;
  }

  .navbar-brand {
    background: $primary;
    background: radial-gradient(circle, rgba(23,26,79,1) 0%, rgba(6,13,32,1) 90%);

    color: $content-bg;
    width: $brandWidth;
    font-weight: bold;
    height: $navbarHeight;
    line-height: $navbarHeight;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-brand:hover {
    color:#D8EF2B;
  }
  .navbar-brand:visited {
    color: $content-bg;
  }


  .navbar-nav {
    .nav-link {
      padding: 1rem;
      font-weight: bold;
      color: #000;

      &.active {
        color: $primary !important;
      }
    }
  }
}



.btn-light {
  @include button-variant($light, $light);
  color: #2D9CDB;
}

.btn-light:hover {
  color: darken(#2D9CDB, 7.5%)
}


.table {

  td {
    vertical-align: middle;
  }

  td:first-child {
    font-weight: 500;
  }

  td:first-child, th:first-child {
    padding-left: $brandWidth
  }

  td:last-child, th:last-child {
    padding-right: $brandWidth
  }

  thead {
    text-transform: uppercase;
    background: $gray-1;
    letter-spacing: 0.15rem;
    color: $gray-6;

    th {
      font-weight: 500;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.75rem;
    }
  }
}

.custom-control {
  display: flex;
}

.custom-control-label {
  font-weight: bold;
  font-size: $font-size-label;
  display: flex;
  align-items: center;
}

.min-width {
  width: 1px;
  white-space: nowrap;
}

.react-datepicker-wrapper {
  display: block !important;
}

.modal-open {
  overflow: unset !important;
}
