@import "../../../assets/scss/custom";

:global {
  .custom-select__control {
    @include input-styles;
  }

  .custom-select__menu {
    z-index: 103 !important;
  }
}

.Invalid {
  :global {
    .custom-select__control {
      border-color: #dc3545;
    }
  }
}

.FullWidthMultiValues {
  :global {
    .custom-select__multi-value {
      width: calc(100% - 10px);
    }

    .custom-select__multi-value__label {
      flex-grow: 1;
    }
  }
}

.EditContainer {
  // padding: 0.25rem 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ffbdad;
    color: #de350b;
  }

  button {
    font-size: $font-size-xs;
    padding: 0.2rem 0.4rem;
  }
}

