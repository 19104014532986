@import "../../../../../../assets/scss/custom";

.Container {
  margin: 1rem 0;
  display: flex;
}

.Action {
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  border-left: 1px solid $gray-5;
  border-top: 1px solid $gray-5;
  border-bottom: 1px solid $gray-5;
  cursor: pointer;
}

.Action:hover {
  background: $gray-6;
}

.Action:first-child {
  border-radius: 5px 0 0 5px;
}

.Action:last-child {
  border-right: 1px solid $gray-5;
  border-radius: 0 5px 5px 0;
}

.ActionIcon {
  color: $gray-4;
}

.ActionText {
  margin: 0.5rem 0 0 0;
  color: $blue-2;
  font-size: $font-size-sm;
  text-align: center;
}
