@import "../../../../../assets/scss/custom";
@import '../../../../../assets/scss/variables';

.Icons {
  display: flex;
  justify-content: flex-end;
  > div {
    margin-right: 0.5rem;
  }
}

.Actions {
  display: flex;
  align-items: center;

  button {
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}