@import '../../../assets/scss/custom';

.Container {
  padding: 0 2rem;
  border-bottom: 3px solid $gray-5;
}

.List {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;

  li {
    display: inline-block;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position:relative;
    cursor: pointer;
    color: $gray-4;
    font-weight: bold;

    svg {
      font-size: 0.5rem;
      margin: -0.25rem 0.5rem 0 0;
      vertical-align: middle;
    }

    &.Active {
      color: $gray-1;
      cursor: default;
    }

    &.Active:after {
      content: '';
      border-radius: 5px;
      margin-bottom: -2.5px;
      height: 5px; 
      width: 2rem;
      position: absolute;
      bottom: 0;
      left: calc(50% - 1rem);
      background-color: $green-2;
    }
  }
}

.TopTab {
  margin: 0.5rem 0 0 0;
  display: inline-block;
  padding: 0.5rem 1rem 0.5rem 1rem;
  position:relative;
  cursor: pointer;
  color: $gray-4;
  font-weight: bold;

  svg {
    font-size: 0.5rem;
    margin: -0.25rem 0.5rem 0 0;
    vertical-align: middle;
  }

  &.Active {
    color: $gray-1;
    cursor: default;
  }

  &.Active:after {
    content: '';
    border-radius: 5px;
    margin-bottom: -2.5px;
    height: 5px; 
    width: 9rem;
    position: absolute;
    bottom: 0;
    left: 0.8rem;
    background-color: $green-2;
  }
}
