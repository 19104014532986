
/* Expired: #FAB2B2 */
/* 3 days to xpired #F8CEA9 */
/* New #A4EBC2 */
/* Draft #BDBDBD */

.Confirmed {
  color: #A4EBC2;
}

.Opened {
  color: #FAB2B2;
}

.Sent {
  color: #F8CEA9;
}

.Draft {
  color: #BDBDBD;
}

.Expiring {
  color: #F8CEA9;
}

.Expired {
  color: #FAB2B2;
}
