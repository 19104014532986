@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/custom";
@import "../../../../assets/scss/bootstrap-helpers";


.hubspotheader {
height:200px;
width:100%;
    background-color: #00bda5;
    background-image: linear-gradient(
        -303deg
        ,#00a4bd,#00afb2 56%,#00bda5
    );
    color: #fff;
}


.Container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $brandWidth;
  justify-content: space-between;

  height: 4.5rem;
  background: $gray-1;
  //position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 102;

  position:fixed;
}


@include media-breakpoint-down(xs) {
  .Container {
    justify-content: unset;
    padding: 0;
    display: grid;
  }
}

.blockHeader {
  border-top: solid 1px rgb(221,219,218);
  border-left: solid 1px rgb(221,219,218);
  border-right: solid 1px rgb(221,219,218);
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background: rgb(243, 242, 242);
}

.blockBody {
  border:solid 1px #ddd;
  border-radius: 0 0 0.25rem 0.25rem;
  padding:10px;
  font-size:13px;
}

.headerRightIcon {
  float: right;
  border: solid 1px #ddd;
  background: rgba(255,255,255,1);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 0.25rem;
}

.headerIcon {
  display:inline-block;
  background: #27AE60;
  color:#ffffff;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius:4px;
  margin-right:10px;
}

.hideBlock {
  display:  none;
}

.linkbtn {
  border:  solid 1px #ddd;
  padding: 5px;
  margin:  5px;
  font-size: 13px;
  color:  rgb(1,118,211);
}