/*
.Header {
  border-bottom: 0;
  padding: 2rem 2rem 1rem 2rem;
}

.Content {
  padding: 0rem 2rem;
}

.Footer {
  border-top: 0;
  padding: 1rem 2rem 2rem 2rem;
  justify-content: flex-start;
}

.XL {
  max-width: 1200px;
}

.Fullscreen {
  max-width: calc(100vw - 1rem);
  user-select: none;

  .Content {
    padding: 0;
  }
}
*/
/* --------- */

.Modal {
	margin-top:1rem;
	margin-bottom:1rem;
}
.Header {
  border-bottom: 0;
  padding: 2rem 2rem 1rem 2rem;
}

.Content {
  padding: 0rem 2rem;
}

.Footer {
  border-top: 0;
  padding: 1rem 2rem 2rem 2rem;
  justify-content: flex-start;
}

.XL {
  max-width: 1200px;
}

.Fullscreen {
  user-select: none;

	max-height:calc(100vh - 2rem);
	max-width:calc(100vw - 2rem);
	height:calc(100vh - 2rem);
	width:100vw;
	margin:1rem;
	overflow-y:auto;

  .Content {
/*    padding: 0;*/
  }

}

.OverflowHeight {
	max-height:100vh; /*calc(100vh - 2rem);*/
	margin:auto;
	max-width:unset;
}

.OverflowHeight > div {
	max-height:calc(100vh - 2rem);
	margin:1rem;
	height:calc(100vh - 2rem);
}
.Overflow {
	overflow-y:auto;
}

@media screen and (max-width:1366px) {
}

@media screen and (min-width:1367px) {
	.OverflowModal {
		right:480px;
		width:unset;
	}

	.OverflowWrap {
		max-width:unset;
	}

	.OverflowHeight {
		max-width:unset;
		margin:1rem;
	}

	.OverflowHeight > div {
		margin:unset;
	}
}

.OverflowFullWidth {
	right:0;
}
