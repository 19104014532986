@import "../../../../../../../assets/scss/custom";

.Container {
  margin-bottom: 1rem;
}

.Highlight {
  border-left: 3px solid #56ccf2;
  padding-left: 1rem;
}

.Name {
  color: $blue-2;
  font-weight: bold;
  font-size: $font-size-sm;
  margin-bottom: 0;
}

.Created {
  color: $gray-4;
  font-size: $font-size-sm;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.Comment {
  color: $text-color-dark-blue;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  margin: 0;
  white-space: pre-wrap;
}
