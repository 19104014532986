@import '../../../assets/scss/custom';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $water-light;
  padding: 0.25rem 2rem;
  margin: 0 -2rem;
  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  span {
    cursor: pointer;
  }
}

.Content {
  padding: 0.5rem 0;
}