@import '../../../../../../assets/scss/variables';

.Container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $brandWidth;
  justify-content: space-between;
}

.Buttons {
  display: flex;
  button:not(:first-child) {
    margin-left: 1rem;
  }
}
