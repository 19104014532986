@import '../../../../../../../../assets/scss/custom';

.Container {
  background-color: $gray-1;
  color: #FFFFFF;
  padding: 1rem;
  height: 100%;
  border-bottom: solid thin white;
}

.Row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;

  p {
    color: #FFFFFF !important;
    margin-bottom: 0 !important;
  }
}

.h1 { font-size: 2.0rem; }
.h2 { font-size: 1.7rem; }
.h3 { font-size: 1.4rem; }
.h4 { font-size: 1.1rem; }
.h5 { font-size: 0.8rem; }
.h6 { font-size: 0.5rem; }

.leftSide {
	text-align:left !important;
}

.rightSide {
	text-align:right !important;
}

.iCol {
		text-align:right;
		padding:2px;
}

.iCol1 {
		padding:2px;

}

.hCol {
	padding:2px;
	text-align:right;
	font-size:0.8em;
	font-weight:bold;
}

.hCol1 {
	padding:2px;
	text-align:left;
	font-size:0.8em;
	font-weight:bold;
}

.header {
	font-size:1.2em;
}

.totalBox {
		margin-top:40px;
		font-weight:bold;
    margin-bottom:40px;
}
/*
.table {
		padding:15px;
}

.table div {
}

.table .hCol,
.table .iCol,
.table .hCol1,
.table .iCol1 {
		border: solid 1px #ff0000 !important;

}

.table .rowi {
		xborder-bottom: solid 1px #ff0000 !important;
}

.iCol {
		xborder-right: solid 1px #ff0000 !important;
}
*/
/*
.table tbody tr:nth-of-type(even) {
	background:rgba(200,200,200,0.1);
}
*/
.table, .table thead {
		color:inherit;
		background:inherit;
}

.table td {
	padding:2px !important;
	overflow:hidden;
	word-break:normal;
}

.table thead td {
	text-transform:none;
	//background: rgba(0,0,0,0.1);
	color:inherit;
	letter-spacing:normal;
	font-weight:900 !important;
}

.editQuantity {
	display:inline-block;
	width:inherit;
	text-align:right;
	background:inherit;
//	border-color:#5cb85c;
	height:24px;
	padding:0;
	margin:0;
	width:70px;
//	outline:2px solid ;
}

.editQuantity:focus {
	background:inherit;
}

.editable {
	display:inline-block;
	padding-right:10px;
	color:rgba(0,0,0,0.2) !important;
}

.strike {
	text-decoration: line-through;
}

.desc {
  padding: 4px 4px 4px 15px !important;
  white-space:pre-line;
}

.wordbreak {
	// word-break: break-all;
}

.tarjous {
	background: rgba(203,236,254,1);
	padding: 10px;
	border: solid 1px #000;
}

.bold {
	font-weight: bold;
}

.info {
	white-space: pre;
}

.palvelukuvaukset {
	width:100%;
	/*border:solid 1px #000;*/
}

.palvelukuvaukset > tr > td {
	font-size:0.8em;
	padding:0px 10px 0px 10px;
	border:solid 1px #000;
}

