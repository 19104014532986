.ContentTypeContainer {
  > label {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
  
}