.Container {
    display: flex;
    flex-direction: column;
}

.Page {
  canvas {
    width: 100% !important;
    height: auto !important;
  } 
}

.MultiPage {
  canvas {
    width: 99% !important;
    height: auto !important;
    margin: auto;
  }
}