@import '../../../../../assets/scss/custom';
@import '../../../../../assets/scss/bootstrap-helpers';
@import '../../../../../assets/scss/variables';

.Container {
    padding: 2rem $brandWidth;
    background: $list-header-bg;
}

.TopColumn {
  border: 1px solid $gray-4;
  background-color: $gray-6;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 49%;
  text-align: center;

  p {
    color: $text-muted;
  }

}

.Box {
  justify-content: space-between;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MiddleColumn {
  border: 1px solid $gray-4;
  background-color: $gray-6;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  max-width: 49%;

  p {
    color: $text-muted;
  }

}
