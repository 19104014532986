@import '../../../../../../../assets/scss/custom';

.Container {
  padding: 0;
  border-bottom: 3px solid $gray-5;
}

.List {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;

  li {
    display: inline-block;
    padding: 0.5rem 2rem 0.5rem 0rem;
    position:relative;
    cursor: pointer;
    font-weight: bold;
    color: $gray-4;

    &.Active {
      color: $green-2;
      cursor: default;
    }

    &.Active:after {
      content: '';
      border-radius: 5px;
      margin-bottom: -2.5px;
      height: 3px; 
      width: 3rem;
      position: absolute;
      bottom: 0;
      left: calc(50% - 2.5rem);
      background-color: $green-2;
    }
  }
}