@import '../../assets/scss/bootstrap-helpers';
@import '../../assets/scss/variables';
@import '../../assets/scss/custom';

.Container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem $brandWidth;
  background: $list-header-bg;
}

.Left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
}

.CreateButton {
  font-weight: 500;
}

.Title {
  margin-bottom: 0;
}

.Right {
  display: flex;
  align-items: center;
}

.ViewsLabel {
  margin-bottom: 0;
  margin-right: 1rem;
  font-weight: 500;
}

.ViewIcon {
  margin-right: 1rem;
}

.Buttons {

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}

@include media-breakpoint-up(md) {
    .Left {
        flex-grow: 0;
        > * {
          margin-right: 1rem;
        }
    }
}

@include media-breakpoint-down(sm) {
  .Left {
    display: grid;
    grid-row-gap: 0.5rem;
  }
}

@media (max-width: $mobile-width) {
  .Left {
    width: 100%;
    margin-bottom: 1rem;
  }

  .Right {
    width: 100%;
  }

  .Container {
    padding: 2rem 1rem;
  }
}

@media (min-width: ($mobile-width + 1)) {
  .Container {
    justify-content: space-between;
  }
}
