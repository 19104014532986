@import "../../../../../../../../../assets/scss/custom";

.Container {
  display: flex;
  flex-direction: column-reverse;
}

.HistoryContainer {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid $gray-5;
}

.Views {
  padding-top: 0.5rem;
}

.View {
  font-size: $font-size-xs;
  font-weight: bold;
}

.ViewIcon {
  color: $gray-4;
  padding: 0 0.5rem 0.5rem;
}

.Title {
  font-weight: bold;
}

.Icon {
  margin-right: 0.5rem;
  width: 1rem;
}

.IconSent {
  color: $gray-4;
}

.IconDeclined, .TitleDeclined {
  color: $red-1;
}

.IconConfirmed, .TitleConfirmed, .Viewed {
  color: $green-2;
}

.SubTitle {
  color: $gray-3;
  font-weight: 500;
  font-size: $font-size-sm;
  margin: 0;
}

.StatusDetails {
  cursor: pointer;
}



