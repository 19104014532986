$green-2: #27AE60;
$blue-2: #2D9CDB;
$water-light: #E1F7FF;

$white: #FFF;

$gray-1: #333333;
$gray-3: #828282;
$gray-4: #BDBDBD;
$gray-5: #E0E0E0;
$gray-6: #F2F2F2;

$red-1: #EB5757;
$yellow-1: #F2C94C;

$text-color-dark: #253448;
$text-color-dark-blue: #124282;

$primary: $green-2;
$secondary: $gray-3;
$light: $gray-6;
$info: $blue-2;
$text-muted: $gray-3;
$link-color: $gray-3;
$enable-rounded: true;

$form-group-margin-bottom: 0.5rem;
$component-active-bg: $blue-2;

$table-bg: #FFFFFF;
$table-striped-order: even;
$table-accent-bg: #F9F9F9;

$modal-md: 50vw;

$body-bg: #ECECEC;
$input-bg: #e5e5e5;
$link-blue-color: $blue-2;

$border-radius: 4px;
$border-radius-sm: 2px;

$table-header-bg: $gray-1;
$form-footer-bg: $gray-1;
$content-bg: #FFFFFF;
$footer-bg: #E7E7E7;
$list-header-bg: #ECECEC;
// $primary: rgb(78, 178, 229);

$font-size-xs: 0.7rem;
$font-size-sm: 0.875rem;
$font-size-md: 0.925rem;
$font-size-label: 0.8125rem;

$mobile-width: 1366px;
$mobile-width-funnel: 768px;

@mixin input-styles {
  //background: $input-bg !important;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  background: #FFF;
  border: 1px solid #BDBDBD;

  &:focus {
    // background: $input-bg;
    background: #FFF;
  }

  &::placeholder {
    color: #828282;
    padding: 0;
    margin: 0;
  }
}

@mixin form-content {
  background: $content-bg;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  h4 {
    margin-bottom: 2rem;
  }
}

@mixin subtitle {
  color: $gray-3;
  font-weight: 500;
  font-size: $font-size-md;
}