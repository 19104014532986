@import '../../../../../../../../../assets/scss/custom';

.Container {
  border: 1px solid $gray-4;
  background: $body-bg;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}


.FormContainer {
  background: $body-bg;
  padding: 0;
}

.ItemTitle {
  padding: 1rem 1rem 0 1rem;
  text-transform: uppercase;
  color: $gray-3;
  font-size: $font-size-sm;
}

.Item {
  border-bottom: 1px solid $gray-4;
  padding-bottom: 0.5rem;

  &:last-child {
    border-bottom: 0;
  }
}