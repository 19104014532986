@import "../../../../../../assets/scss/custom";
@import "../../../../../../assets/scss/bootstrap-helpers";

.Container {
  padding: 1rem;
  border: 1px dashed $secondary;
  border-radius: $border-radius;
  cursor: pointer;
}

.Active {
  background: $water-light;
  border: 1px solid $blue-2;
}

.Add {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.Title {
  text-align: center;
  color: $gray-4;
  font-weight: bold;
}