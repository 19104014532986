@import "../../../../../../../../assets/scss/bootstrap-helpers";
@import "../../../../../../../../assets/scss/custom";

.Content {
  white-space: pre-wrap;
  p {
    margin: 0;
  }
}

.Header {
  display: none;
}

.Line {
  display: none;
}

@include media-breakpoint-only(xs) {
  .Header {
    display: block;
    font-weight: bold;
  }
  .Line {
    display: block;
    border-top: 1px solid $gray-5;
    margin: 0.5rem 0;
  }
}
