@import '../../../../../../assets/scss/bootstrap-helpers';

.Container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 3rem;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Content {
  -webkit-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
  }
}

.BlockContent {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}


.BlockName {
  text-align: center;
  padding: 1rem 0;
  font-weight: normal;
  font-size: 2rem;
}

.Horizontal {
  display: grid;
  grid-template-columns:repeat(auto-fit, minmax(300px , 1fr));
  background: inherit;
}

.Vertical {
 background: inherit; 
}


@include media-breakpoint-only(xs) {
  .Container {
    padding: 0.5rem;
  }

  .BlockContent {
    grid-auto-flow: row;
  }
}

.BlockContent, .BlockContent > div {
  background: inherit;

}