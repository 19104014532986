@import '../../../../../../../assets/scss/custom';

.Container {
  background-color: $gray-1;
  color: #FFFFFF;
  padding: 1rem;
  height: 100%;
  border-bottom: solid thin white;
}

.Row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;

  p {
    color: #FFFFFF !important;
    margin-bottom: 0 !important;
  }
}

.h1 { font-size: 2.0rem; }
.h2 { font-size: 1.7rem; }
.h3 { font-size: 1.4rem; }
.h4 { font-size: 1.1rem; }
.h5 { font-size: 0.8rem; }
.h6 { font-size: 0.5rem; }

.leftSide {
	text-align:left !important;
}

.rightSide {
	text-align:right !important;
}
