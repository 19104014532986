
@import '../../../../../../../assets/scss/bootstrap-helpers';

.Container {
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  // margin: 1rem;
}

.Customer, .Organization {
  display: flex;
  justify-content: center;
  padding: 2rem;

  p {
    margin: 0;
    font-size: 1rem;
  }

  .Name {
    font-size: 1.25rem;
  }
}

.Divider {
  width: 1px;
  height: 95%;
  background: #000;
  position: relative;
  margin: 0 30px;
  padding: 1rem 0;
  align-self: center;
}

.Divider:after {
  content: "&";
  position: absolute;
  right: -30px;
  text-align: center;
  top: 50%;
  width: 60px;
  height: 60px;
  background: #333;
  color: #FFF;
  font-size: 2em;
  border-radius: 50px;
  padding-top: 9px;
  margin-top: -30px;
}

@include media-breakpoint-down(sm) {
  .Container {
    grid-template-rows: 1fr 60px 1fr;
    grid-template-columns: auto;
    text-align: center;
  }

  .Divider {
    position: relative;
    height: 1px;
    width: 95%;
    padding: 0 1rem;
    justify-self: center;
  }

  .Divider:after {
    left: 50%;
    margin-left: -30px;
  }
}

.ar {
}

