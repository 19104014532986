@import '../../../assets/scss/custom';

.Footer {
  justify-content: space-between;
}

.DiscardButton {
    color: $gray-4;
  
    &:hover {
      text-decoration: none;
    }
  
    &:active {
      text-decoration: none;
    }
  
    &:focus {
      text-decoration: none;
    }
  
}
  
.SaveButton {
    background: $blue-2;
    width: 5rem;
    height: 2.3rem;
}
