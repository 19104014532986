@import "../../../../../../assets/scss/custom";
@import "../../../../../../assets/scss/bootstrap-helpers";

.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;
  z-index: 101;
}

@media (min-width: ($mobile-width + 1)) {
  .HasNavigation {
    height: calc(100% - 59px);
  }
}

@media (max-width: $mobile-width) {
  .Container {
    transition: all 500ms;
    background: #fff;
    margin-top: 1200px;
    top: 1200px;
    max-width: 100%;
  }
  .MobileVisible {
    margin-top: 0;
    top: 0;
  }
}

.Body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iframeHolder {
		position:fixed;
		top:0;
		left:0;
		bottom:0;
		right:0;
		border:solid 5px #000;
		padding:50px;
		margin:50px;
		background:rgba(255,255,255,0.9);
}


