@import "../../../../../../assets/scss/custom";

.Title {
  display: block;
  margin-bottom: $form-group-margin-bottom;
}

.Comments {
  display: flex;
  flex-direction: column-reverse;
}
