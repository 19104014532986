@import '../../../../../../../assets/scss/bootstrap-helpers';

.Container {
    background: #FFF;
}

.AuditLogs {
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: 4rem;
    row-gap: 2rem;
}

.Header {
    font-weight: bold;
}

.Title {
    text-align: center;
    padding: 1rem 0;
    font-weight: normal;
    font-size: 2rem;
}

.Center {
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@include media-breakpoint-only(xs) {
    .AuditLogs {
        grid-template-columns: none;
        row-gap: 0.5rem;
    }
    
    .Header {
        display: none;
    }
}
