@import "../../../../../assets/scss/custom";

.headerRow {
  display: grid;
  color: white;
  height: 2.875rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.offers {
  padding: 0 12.5%;
}

.funnelContainer {
  display: grid;
  grid-template-areas: 
  "draftHeader"
  "draftContent"
  "sentHeader"
  "sentContent"
  "openedHeader"
  "openedContent"
  "expiredHeader"
  "expiredContent"
  "noOffers";
  grid-auto-columns: 1fr;
}

.noOffers {
  display: grid;
  grid-area: noOffers;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.arrow {
  display: none;
}

.DraftHeader, .SentHeader, .OpenedHeader, .ExpiredHeader {
  @extend .headerRow;
  background: #4F4F4F;
}

.BeforeHeader, .AfterHeader {
  @extend .headerRow;
  display: none;
}

.DraftContent {
  grid-area: draftContent; 
}

.SentContent {
  grid-area: sentContent; 
}

.OpenedContent {
  grid-area: openedContent; 
}

.ExpiredContent {
  grid-area: expiredContent; 
}

@media (min-width: $mobile-width-funnel) {
  .funnelContainer {
    grid-template-areas: 
      "beforeHeader draftHeader sentHeader openedHeader expiredHeader afterHeader"
      ". draftContent sentContent openedContent expiredContent ."
      ". . noOffers noOffers . .";
  }

  .arrow {
    display: grid;
    background: #333;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    z-index: 6;
    width: 1.43rem;
    height: 100%;
    position: absolute;
    margin-left: -1px;
  }

  .BeforeHeader {
    display: grid;
    grid-area: beforeHeader;
    margin-right: -1px;
    background: #4F4F4F;
  }
  
  .DraftHeader {
    grid-area: draftHeader;
    background: #4F4F4F;
  }
  
  .SentHeader {
    grid-area: sentHeader;
    background: #333;
    .arrow {
      background: #4F4F4F;
    }
  }
  
  .OpenedHeader {
    grid-area: openedHeader;
    background: #4F4F4F;
    .arrow {
      background: #333;
    }
  }
  
  .ExpiredHeader {
    grid-area: expiredHeader;
    background: #333;
    .arrow {
      background: #4F4F4F;
    }
  }
  
  .AfterHeader {
    display: grid;
    grid-area: afterHeader;
    margin-left: -1px;
    background: #333;
  }
}
