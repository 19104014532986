@import '../../../assets/scss/custom';

.Container {
  background: $content-bg;
  border-radius: $border-radius;
  border: 1px solid $gray-4;
  cursor: pointer;

  :global {
    .drag-handle {
      cursor: move;
      color: $gray-4;
      margin: 0 1rem 0 0.5rem;
    }    
  }
}

.Active {
  background: $water-light;
  border: 1px solid $blue-2;

  :global {
    .drag-handle {
      color: $blue-2;
    }    
  }
}



