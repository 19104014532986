@import '../../../assets/scss/custom';

.created {
  font-size: 0.875rem;
}

.date {
  color: #828282;
  font-weight: 500;
  font-size: 0.925rem;
}