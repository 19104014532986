@import "../../../assets/scss/custom";

.SortIcon {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  border: 1px solid $gray-4;
  background: $gray-6;

  display: inline-block;
  vertical-align: top;
  margin-top: 0.1rem;
  text-align: center;
  cursor: pointer;

  svg {
    font-size: 0.6rem;
    vertical-align: top;
    margin-top: 2px;
    color: #4f4f4f;
  }
}

.Active {
  background: $primary;
  border-color: #1e8449;
  svg {
    color: #FFF;
  }
}

.ActiveAsc svg {
  font-size: 0.8rem;
  margin-top: 3px;
}

.ActiveDesc svg {
  font-size: 0.8rem;
  margin-top: -2px;
}
