@import "../../../../../assets/scss/custom";

.TableRow {
  border-radius: 4px;
  background: $content-bg;
  margin: 1rem 0;
  padding: 1rem;
  // border-bottom: 1rem solid $body-bg;
  font-weight: 500;
  cursor: pointer;
  
  td {
    vertical-align: middle;
  }
}
