@import '../../../assets/scss/custom';

.ErrorMessage {
    margin-top: 1rem;
}


.Container {
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

.LoginBox {
    width: 480px;
    background: #FFF;
    border-radius: 4px;
    padding: 3rem;
}

.Title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2rem;
}

.Inputs {
    margin-bottom: 2rem;
}

.ButtonContainer {
    text-align: center;
}

.Button {
    width: 50%;
    background-color: #D8EF2B;
    border-color: #D8EF2B;
    color: #060D20;
}

.LinksContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;

    button {
        color: #FFF;
    }
}

.Border {
    border-right: 1px solid #219653;
}

.LoginQuestion {
    text-align: center;
    margin-top: 2rem;
    a {
        color: #27AE60;
    }
}
