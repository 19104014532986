@import "../../../../../../assets/scss/custom";

.CanvasContainer {
  max-width: 100%;
  min-height: 50vh;
  background: $gray-6;
  cursor: crosshair;
}

.Input {
  font-family: "Cedarville Cursive";
  font-size: 4rem;
  background: $gray-6 !important;
  border-radius: 0;
  border: none;
}

@media (max-width: $mobile-width) {
  .Input {
    font-size: 1.35rem;
  }
}
