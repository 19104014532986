@import "../../../../../../../assets/scss/custom";

.Container {
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0;
  }

  img {
    max-height: 2rem;
    max-width: 100%;
  }
}

.Signature {
  flex-grow: 0.5;
  flex-basis: 0;
}

.Cursive {
  font-family: "Cedarville Cursive" !important;
  font-size: 1.35rem;
}

.Signature:not(:first-child) {
  margin-top: 1rem;
}

@media (min-width: $mobile-width-funnel) {
  .Container {
    flex-direction: row;
  }

  .Signature:not(:first-child) {
    margin-top: 0;
  }
}
