@import "../../../assets/scss/custom";
@import "../../../assets/scss/variables";

.Container {
  min-height: 100%;
  display: grid;
  grid-template-areas: "content sidebar";
  grid-template-columns: auto 480px;
  grid-template-rows: 100%;
  flex-grow: 1;
}

@media (max-width: $mobile-width) {
  .Container {
    grid-template-areas: 
    "sidebar"
    "content";
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
