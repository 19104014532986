@import "../../../../../../assets/scss/custom";
@import "../../../../../../assets/scss/bootstrap-helpers";

.Container {
  
}

.Border {
  padding: 1rem;
  border: 1px dashed $secondary;
  border-radius: $border-radius;
}

.Add {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.Title {
  text-align: center;
  color: $gray-4;
  font-weight: bold;
}