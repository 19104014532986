.Button {
  border: none;
  border-radius: 0;
  background: none;
  color: #828282;
  font-size: 1.5rem;
}

.Button:hover {
  color: #5c5c5c;
  text-decoration: none;
}

.Button:active {
  color: #5c5c5c;
  text-decoration: none;
}

.Button:focus {
  color: #5c5c5c;
  text-decoration: none;
}

.Button:after {
  content: '\2807';
}

.Menu button:not(:first-child) {
  margin: 0 !important;
}
