@import "../../../../../../assets/scss/custom";

.Info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  font-size: $font-size-sm;
  span {
    color: $gray-3;
  }
  button {
    color: $green-2;
    padding-right: 0;
  }
}

.ErrorMessage p {
  margin: 0
}
