@import '../../../../../../../../assets/scss/custom';

.Button {
		width:24px;
		padding:0;
		height:24px;

		position:absolute;
		left:5px;
		top:5px;

}

.bsize1 { font-size:1.0em; }
.bsize2 { font-size:0.9em; }
.bsize3 { font-size:0.8em; }
.bsize4 { font-size:0.7em; }
.bsize5 { font-size:0.6em; }
.bsize6 { font-size:0.5em; }

.customHolder {
		position:relative;
}

.customInput {
}

.customInput2 {
	text-align:center;
	font-size:0.8em;
	font-weight:bold;
	padding:1px;
}



.trashBtn {
		position:absolute;
		right:5px;
		top:5px;
}

.iCol {
		padding:1px !important;
}

.iCol input {
		text-align:right;
		padding:2px;
}

.hCol {
	padding:1px !important;
}

.hCol input {

}

.libraryItem {
	cursor:pointer;
	padding:5px;
}

.libraryItem:hover {
	background:#dddddd;
}

.redborder {
	border: solid 1px #ff0000;
}
.greenborder {
	border: solid 1px #00ff00;
}

.palvelukuvaukset {
	width:100%;
	/*border:solid 1px #000;*/
}

.palvelukuvaukset > tr > td {
	font-size:0.8em;
	padding:0px 10px 0px 10px;
	border:solid 1px #000;
}

.myinput {
	width:100%;
	border: none;
}

.label {
	font-weight: bold;
	font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-top: 1.0rem;
}

