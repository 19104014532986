@import "../../../../../../assets/scss/custom";

.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - 131px);
  width: 100%;
  max-width: 480px;
  z-index: 101;
}

.Body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.FooterButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DeleteButton,
.DeleteButton:hover {
  color: $red-1;
  font-weight: bold;
}

.OfferComments {
  margin-top: 1rem;
}

.Link {
  margin-bottom: 1rem;
  position: relative;

  span {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    color: $gray-3;
    cursor: pointer;
  }

  input {
    padding-right: 2rem;
    background: $gray-6 !important;
  }
}

@media (max-width: $mobile-width) {
  .Container {
    position: relative;
  }
}
