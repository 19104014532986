.Container {
  text-align: center;
}

.Hash {
  word-break: break-word;
}

.AuditLog {
  :global {
    button {
      padding: 0;
    }
  }
}
