@import '../../../assets/scss/custom';

.Container {
  position: relative;
}

.Color {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: 1px solid $gray-4;
}

.Clear {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
  color: $gray-4;
}

.Input {
  @include input-styles;
  display: flex;
  background: #FFF !important;
  border-radius: 4px;
  padding-left: 34px;
}

.TwitterContainer {
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 1;
}

.SketchContainer {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  z-index: 1;
}